import { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumb.jpg';
// import PlayBtn from '../Assets/Images/play-button.png';
import PlayBtn from '../Assets/Images/playbtn.png';
import Clock from '../Assets/Images/clock.png';
import Logo from '../Assets/Images/logo_EL.png';
import introBgImg from '../Assets/Images/intro-bg.jpg';

import { Close } from "@mui/icons-material";
import '../App.scss';
import Video from '../Components/Video';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Start = props => {

  const { windowWidth }                   = useWindowDimensions();

  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  const vidRef                            = useRef(null);
  
  const display = searchParams.get("display");

  useEffect(() => {
    if(display === "preview") {
      console.log("changed background img for embedded preview display...");
      document.body.style.background = `#242424 url(${introBgImg}) repeat`;
    } else {
      document.body.style.background = `#414042`;
    }
  }, [display]);


  const howItWorksLink = () => {
    if(searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const playVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.opacity = "0";
      document.getElementsByClassName("intro")[0].style.width = "0%";
      document.getElementsByClassName("intro")[0].style.padding = "0";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      document.getElementsByClassName("video")[0].style.width = "100%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      if(display === "preview") {
        document.getElementsByClassName("video")[0].style.background = `#242424 url(${introBgImg}) repeat`;
      } else {
        document.getElementsByClassName("video")[0].style.backgroundColor = "#414042";
      }
      document.getElementsByClassName("video")[0].style.borderRadius = "0";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "44%";
      document.getElementsByClassName("intro")[0].style.padding = "0 3%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      if(display === "preview") {
        document.getElementsByClassName("video")[0].style.background = `#242424 url(${introBgImg}) repeat`;
      } else {
        document.getElementsByClassName("video")[0].style.backgroundColor = "#414042";
      }
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "44%";
      document.getElementsByClassName("intro")[0].style.padding = "0 3%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      if(display === "preview") {
        document.getElementsByClassName("video")[0].style.background = `#242424 url(${introBgImg}) repeat`;
      } else {
        document.getElementsByClassName("video")[0].style.backgroundColor = "#414042";
      }
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={PlayBtn} onClick={(e) => playVideo(e)} alt="play button icon" />
    }
  }

  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Church-Growth-Solutions-v2.mp4";

  return (
    <>
    <div className="container start">
      {/* <Video vidsrcURL={vidsrcURL} thumbnail={VideoThumbnail} playButton={PlayBtn} /> */}
      
      {/* <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source src={vidsrcURL} type="video/mp4" />
        </video>
        {showThumbnail()}
        {showPlayButton()}
        {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#dddddddd", opacity: 0.75, zIndex: 99, padding: "15px", top: "15px", cursor: "pointer", 
        right: windowWidth > 1100 ? "60px" : "20px" }} onClick={(e) => pauseVideo(e)} />}
      </div> */}

      <div className="intro">
        {searchParams.get("leader") ? 
          <>
          <h1 style={{ margin: windowWidth > 830 ? "45px auto 30px" : "30px auto 15px" }}>What is your most significant barrier to growth?</h1>
          <h2 style={{ margin: "15px auto", maxWidth: "42ch" }}>Measure your church's performance in 4 crucial areas and identify key areas of growth and critical red flags.</h2>
        </> : <>
          <h1>What is your most significant barrier to growth?</h1>
          <h2>Measure your church's performance in 4 crucial areas affecting impact, and:</h2>
          </>
        }

        {searchParams.get("leader") ? null : <ol className="intro-list">
          <li>View everyone's results on one dashboard</li>
          <li>Identify key areas of growth and critical red flags</li>
          <li>See where you're aligned and misaligned as a team</li>
          <li>Align your team to unlock intentional church growth</li>
        </ol>}

        {/*<br />
        <h2>Which of the 9 opportunities should be your #1 priority?</h2>*/}
        <div className="completion">
          {searchParams.get("display") === "preview" ? <a className="cta" href="https://els2.bleat.church/assessment" target="_blank" rel="noreferrer">Get Started</a> : <Link to={assessmentLink()} className="cta">Get Started</Link>}
          {/* <Link to={howItWorksLink()} className="cta">Get Started</Link> */}
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>4 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
        {/* <div className="logos-section">
          {windowWidth < 831 && <>
            <img className="logo-left" src={Logo} alt="Executive Leadership Solutions Logo" srcset="" />
            <div className="logo-separator"></div>
          </>}

          <img className="logo-right" src={liquidLogo} alt="Liquid Church Logo" srcset="" />
          
        </div> */}
      </div>
    </div>
    </>
  );
}
export default Start;